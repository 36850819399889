import React, { useState } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './style.scss';
import israelLivesService from "../../../services/israelLives.service";
import { Button, Grid } from "@mui/material";

const AddSoldiers = () => {

    const [error, setError] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [success, setSuccess] = useState(false);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(),
        request: Yup.string().required(),
    });

    const formik = useFormik({
        initialValues: {
            name: "",
            request: ""
        },
        validationSchema,
        onSubmit: async values => {
            setError(false); setDisabled(true);
            const res = await israelLivesService.onlyInsertName(values)
            if (!res || res?.data?.error) {
                setError("שגיאה במערכת,נסו שוב מאוחר יותר, או צרו קשר בטופס.");
                setDisabled(false);
            } else {
                setSuccess(true)
            }
        },
    });

    const getError = (field) => {
        return formik.errors[field] ? "error" : "";
    }

    const moreName = () => {
        setSuccess(false)
        formik.resetForm();
        setDisabled(false);
    }

    return (
        <div id="joining" className="joining-soldiers">
            <div className="content">
                <form>
                    <p>מלאו את הפרטים הבאים:</p>
                    <div className="row">
                        <div>
                            <div>
                                <label>שם החייל / שם הפצוע</label>
                                <input
                                    name="name"
                                    type="text"
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                    className={getError("name")}
                                />
                            </div>
                        </div>
                        <div>
                            <div>
                                <label>מלאי בקשה לתפילה</label>
                                <textarea
                                    name="request"
                                    type="request"
                                    rows="2"
                                    onChange={formik.handleChange}
                                    value={formik.values.request}
                                    className={getError("request")}
                                />
                            </div>
                        </div>
                    </div>
                    <button type="submit" disabled={disabled} onClick={formik.handleSubmit}>הכניסי את השם</button>
                </form>
                {error && <p>{error}</p>}
                {
                    success && <>
                        <Grid item xs={12}>
                            <p>השם הוכנס בהצלחה</p>
                            <Button variant="outlined" onClick={moreName}>לחצי להכניס שם נוסף</Button>
                        </Grid>
                    </>
                }
            </div>
        </div>
    );
}

export default AddSoldiers;