import http from "./http-common";

export const sendData = async (path, data = {}) => {
  try {
    return await http.post(path, data);
  } catch (err) {
    if(err?.response?.status == 401) {
      localStorage.removeItem('token');
      window.location.assign('/admin');
    } else {
      console.log(`status Code ${err?.response?.status} message ${err?.response?.data}`)
    }
  }
}