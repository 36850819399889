import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import dataService from '../../../services/data.service';
import { arrangeDataBeforesending } from './utils';
import ModalCheckMail from './ModalCheckMail';
import ModalPropertyEmail from './ModalPropertyEmail';

const Actions = ({ setLoader, setLoad, load, type, data }) => {

    const [open, setOpen] = useState(false)
    const [email, setEmail] = useState("")
    const [propertyEmail, setPropertyEmail] = useState(false)

    const createLottery = async () => {
        setLoader(true)
        await dataService[`createLottery${type}`]();
        setLoader(false)
        setLoad(load + 1)
    }

    const removeLottery = async () => {
        setLoader(true)
        await dataService[`removeLottery${type}`]();
        setLoader(false)
        setLoad(load + 1)
    }

    const removeDuplicate = async () => {
        setLoader(true)
        await dataService[`removeDuplicate${type}`]();
        setLoader(false)
        setLoad(load + 1)
    }

    const removeStatusDone = async () => {
        setLoader(true)
        await dataService.removeStatusDone({ type });
        setLoader(false)
    }

    const sendEmailForLottery = () => {
        setLoader(true)
        dataService[`sendEmailForLottery${type}`]();
        setLoader(false)
    }

    const downloadFile = async () => {
        setLoader(true)
        const dataToExport = await arrangeDataBeforesending(data)
        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, `file-${type}`);
        XLSX.writeFile(workbook, `file-${type}.xlsx`);
        setLoader(false)
    }

    const sendCheckEmail = () => {
        if (email) dataService.sendCheckEmail({ type, email });
        setOpen(false)
    }

    return (
        <div className="actions">
            <Button variant="outlined" onClick={createLottery}>יצירת הגרלה</Button>
            <Button variant="outlined" onClick={removeLottery}>מחיקת הגרלה</Button>
            <Button variant="outlined" onClick={() => setPropertyEmail(true)}>עדכון שדות המייל</Button>
            <Button variant="outlined" onClick={() => setOpen(true)}>שליחת מייל לבדיקה</Button>
            <Button variant="outlined" onClick={sendEmailForLottery}>שליחת מיילים</Button>
            <Button variant="outlined" onClick={removeDuplicate}>מחיקת כפולים</Button>
            <Button variant="outlined" onClick={removeStatusDone}>מחיקת מי שנשלח מייל</Button>
            <Button variant="outlined" onClick={downloadFile}>הורדה לקובץ</Button>
            <ModalCheckMail open={open} setOpen={setOpen} email={email} setEmail={setEmail} action={sendCheckEmail} />
            { propertyEmail && <ModalPropertyEmail type={type} setOpen={setPropertyEmail}  />}
        </div>
    )
}

export default Actions;