import React, { useEffect, useState } from "react";
import { Header, About, OurBabies, Instructions, Joining, QAndA, ContactForm } from "../components";
import usersService from "../services/users.service";
import Donation from "./Donation";
// import IsraelLivesPopUp from "./IsraelLivesPopUp";
import './style.scss';

function Home() {

  const [contactForm, setContactForm] = useState(false)

  return (
    <div className="container">
      <Header setContactForm={setContactForm} />
      <About />
      {/* <OurBabies /> */}
      <Instructions />
      <Joining  open={contactForm} setOpen={setContactForm} />
      <QAndA />
      <Donation />
      <ContactForm open={contactForm} setOpen={setContactForm} />
      {/* <IsraelLivesPopUp /> */}
    </div>
  );
}

export default Home;
