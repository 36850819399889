import axios from "axios";
import { server } from "../config"

export default axios.create({
  baseURL: server,
  headers: {
    "Content-type": "application/json"
  },
  params: {
    token: localStorage.getItem('token') || null
  }
})