import React, { useState } from "react";
import Menu from "../Menu";
import './style.scss';
import banner from "./img/banner.svg"
import ellipse from "./img/ellipse.svg"
import ellipseTwo from "./img/ellipse-two.svg"
import { Modal } from "@mui/material";

function Header({ setContactForm }) {

  const [open, setOpen] = useState(false)

  return (
    <header>
      <img className="banner" src={banner} />
      <img className="ellipse-one" src={ellipse} />
      <img className="ellipse-two" src={ellipseTwo} />
      <div className="content-float">
        <Menu setContactForm={setContactForm} />
        <div className="content">
          <div className="title">
            <p>“כל המבקש רחמים על חברו,</p>
            <p>והוא צריך לאותו הדבר</p>
            <p>הוא נענה תחילה” <span className="source">(בבא קמא צ"ב)</span> <span className="readMore" onClick={() => setOpen(true)}>פירוט</span></p>
          </div>
          <p>מיזם תפילה ותמיכה לנשים מאותגרות פוריות ולרווקות ממתינות.</p>
          <div className="action">
            <a href="#joining">להצטרפות</a>
            <a href="#instructions">איך זה עובד</a>
          </div>
        </div>
      </div>
      <div className="bottom"> </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal">
          <div class="close" onClick={() => setOpen(false)}></div>
          <div className="content">
            <p>
              ידועים דברי חכמים: "כל המבקש רחמים על חברו והוא צריך לאותו דבר, הוא נענה תחילה" (בבא קמא צב.). כלומר, אם אדם מתפלל עבור חברו, והמתפלל עצמו זקוק לאותה ישועה עליה הוא מתפלל עבור חברו, הוא נענה תחילה. המקור לכלל זה הוא ממספר פסוקים, אחד מהם הוא: "וַה' שָׁב אֶת שְׁבוּת אִיּוֹב, בְּהִתְפַּלְלוֹ בְּעַד רֵעֵהוּ" (איוב מב, י).
            </p>
            <p>
              מדוע זה כך? רבינו ניסים (הר"ן) בדרשותיו, מבאר זאת כך: כמו צינור שדרכו עוברים מים מהמעיין אל השדות, אשר לפני שהמים משקים את השדות, הצינור הוא הראשון לקבל תועלת המים, כך מי שמתפלל שיתברך חברו, הרי המתפלל כאותו צינור, שהוא מקבל תחילה את הברכות.
            </p>
            <p>
              יש לכך הסבר מהותי:
              בורא עולם מתנהג עם האדם במידה כנגד מידה. כאשר אדם יוצא מה"אגו" האופף אותו, פונה מעיסוקיו ומעורר רחמים עבור חברו, במידה כנגד מידה, גם בורא עולם "פונה מעיסוקיו", מאזין לקול תפילת אותו אדם, וממלא את משאלות ליבו.
            </p>
          </div>
        </div>
      </Modal>
    </header>
  );
}

export default Header;
