import dataService from '../../services/data.service';

export const progeny = async (values, setError, setSent) => {
    if (values.nameOfWoman && values.motherOfWoman && values.nameOfHusband && values.motherOfHusband && values.email && values.tfila) {
        const data = {
          wife: `${values.nameOfWoman} בת ${values.motherOfWoman}`,
          husband: `${values.nameOfHusband} בן ${values.motherOfHusband}`,
          email: values.email,
          prayer: values.tfila,
          updater: values.checkBoxMail,
          status: 0
        }
        const res = await dataService.insertProgeny(data);
        if(res?.data?.success)
            setSent(true)
        else {
            setError("קרתה שגיאה בתהליך, נסו מאוחר יותר.")
        }
      } else {
        setError("יש למלא את כל השדות")
      }
}

export const shidduch = async (values, checkGender, setError, setSent) => {
    if (values.nameOfHusband && values.motherOfHusband && values.email && values.tfila) {
        const data = {
          name: `${values.nameOfHusband} ${checkGender ? "בן" : "בת"} ${values.motherOfHusband}`,
          email: values.email,
          prayer: values.tfila,
          updater: values.checkBoxMail,
          status: 0
        }
        const res = await dataService.insertShidduch(data);
        if(res?.data?.success)
            setSent(true)
        else 
            setError("קרה שגיאה בתהליך, נסו מאוחר יותר.")
      } else {
        setError("יש למלאות את כל השדות")
      }
}