import React, { useState } from 'react';
import { Grid, TextField, Paper, Button } from '@mui/material';
import usersService from '../../../services/users.service';

const Login = ({ setVerify }) => {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const onSubmit = async () => {
        if(username && password) {
            let res = await usersService.login({ username, password })
            res = res.data
            if (res?.error) {
                setError(res.message)
            } else if (res?.token) {
                localStorage.setItem("token", res.token)
                window.location.reload();
            } else {
                setError("Failed")
            }
        } else {
            setError("All Field Required")
        }
    }
    return (
        <div style={{ padding: 30 }}>
        <Paper>
            <Grid
            container
            spacing={3}
            direction={'column'}
            justify={'center'}
            alignItems={'center'}
            >
                <TextField variant="outlined" label="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                    <TextField variant="outlined" label="password" value={password} type="password" onChange={(e) => setPassword(e.target.value)} />
                <Grid item xs={12}>
                    <Button fullWidth onClick={() => onSubmit()}> Login </Button>
                </Grid>
            {error && <p>{error}</p>}
            </Grid>
        </Paper>
        </div>
    );
};

export default Login;
