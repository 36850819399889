import React, { useState, useEffect } from 'react';
import axios from 'axios';

function UploadImage() {
  const [file, setFile] = useState(null);
  const [images, setImages] = useState([]);

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = () => {
    axios.get('/images').then((response) => {
      setImages(response.data);
    });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = () => {
    const formData = new FormData();
    formData.append('image', file);

    axios.post('/upload', formData).then(() => {
      fetchImages();
    });
  };

  return (
    <div>
      <h1>Image Management</h1>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button>

      <h2>Images</h2>
      <ul>
        {images.map((image) => (
          <li key={image._id}>
            <img src={`/uploads/${image.filename}`} alt={image.filename} />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default UploadImage;

