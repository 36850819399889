import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { Home, Admin } from "./components";
import "./index.css"
import authService from './components/Administration/authService';
import UploadImage from "./components/UploadImage";
import Stories from "./components/Stories";
// import IsraelLives from "./components/IsraelLives";

function App() {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate an asynchronous authentication check
    console.log(authService.isAuthenticated)
  }, []);

  // const PrivateRoute = ({ element, ...rest }) => {
  //   const navigate = useNavigate();

  //   if (isLoading) {
  //     return <h2>Loading...</h2>; // Show a loading screen while checking authentication status
  //   }

  //   if (!authService.isAuthenticated()) {
  //     // Redirect to login page if not authenticated
  //     navigate('/'); // Change this to the login page path
  //     return null;
  //   }

  //   return element;
  // };


  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/stories" element={<Stories />} />
      <Route exact path="/admin/*" element={<Admin />} />
      <Route exact path="/UploadImage" element={<UploadImage />} />
      {/* <Route exact path="/israelLives" element={<IsraelLives />} /> */}
      {
        isLoading &&
        <Route path="/private" element={<Admin />} />
      }
    </Routes>
  );
}

export default App;