import { sendData } from "./sendData";

class AdminDataService {
  insertSalvation(data) {
    return sendData("/insertSalvation", data)
  }
  deleteSalvation(data) {
    return sendData("/deleteSalvation", data)
  }
  getSalvation() {
    return sendData("/getSalvation")
  }
  updateCheckStories(data) {
    return sendData("/updateCheckStories", data)
  }
  updateDataEmail(data) {
    return sendData("/updateDataEmail", data)
  }
}

export default new AdminDataService();