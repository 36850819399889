import React from "react";

export default function SentSucsses(props) {
    console.log(props)
    return (
        <div className="sentSucsses">
            <p>הטופס נשלח בהצלחה!</p>
            <button onClick={() => { props.setSent() }}>להעברת שם נוסף</button>
        </div>
    )
}