import { sendData } from "./sendData";

class SalvationDataService {
  getSalvationFront() {
    return sendData("/getSalvationFront", { homepage: true });
  }

  getSalvationAll() {
    return sendData("/getSalvationFront");
  }
}

export default new SalvationDataService();