import React from "react";
import './style.scss';
import background from "./img/background.svg"

function Donation() {
  return (
    <div className="donation">
      {/* <img className="background" src={background} /> */}
      {/* <div className="content-float"> */}
        <div className="title">
          <p><strong>מיזם תפילה - באהבה ❤</strong>
          <strong className="weight">יחד אתכם מגדילה את הפעילות והחשיפה לכלל האוכלוסיה</strong></p>
          <p>(ניתן לתרום מכספי מעשרות)</p>
          <p className="association">לעמותה אישור מס הכנסה לעניין תרומות לפי סעיף 46 לפקודה</p>
        </div>
        <a target="_blank" href="https://www.matara.pro/nedarimplus/online/?mosad=7002101">לתרומה</a>
      {/* </div> */}
    </div>
  );
}

export default Donation;
