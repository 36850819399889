import React, { useEffect, useState } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, Paper, TableRow, TablePagination, TableFooter, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import dataService from '../../../services/data.service';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import Actions from './Actions';
import Loader from './Loader';

const Prongry = () => {

    const [loader, setLoader] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(false)
    const [open, setOpen] = useState(false)

    const head = { wife: "שם האישה", husband: "שם הבעל", email: "אימייל", prayer: "סוג התפילה", status: "מצב הבקשה", namePrayer: "שם לתפילה" }
    const rows = ["wife", "husband", "email", "prayer", "status", "namePrayer"];
    const status = ["נכנס למערכת", "התקבל שם", "נשלח מייל"];

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [load, setLoad] = useState(0)

    useEffect(() => {
        const fetchData = async () => {
            setUsers([])
            const res = await dataService.getDataByType({ type: "Progeny" });
            setLoader(false)
            if (res?.data)
                setUsers(res.data)
            else
                setError(true)
        }
        setLoader(true);
        fetchData();
    }, [load])

    const getDataPrayer = (data) => {
        const prayer = users.find(u => u._id == data.id);
        setOpen(prayer)
    }

    const deleteName = async (id) => {
        const res = await dataService.deleteById({ id, type: "Progeny" });
        if (res.data?.success)
            setUsers(users.filter(f => f["_id"] != id) )
        else setError(true)
    }

    return (
        <div className="Prongry">
            {error ? <p>An unexpected error occurred, try again later</p> :
                loader ? <Loader /> :
                        <>
                        <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="custom pagination table">
                                        <TableHead>
                                            <TableRow>
                                                {
                                                    rows.map((value, key) => {
                                                        return <TableCell align="center" key={key}>{head[value]}</TableCell>
                                                    })
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {(rowsPerPage > 0
                                                ? users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                : users
                                            ).map((row, key) => (
                                                <TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    {
                                                        rows.map((value, key) => {
                                                            return (
                                                                value == "namePrayer" ?
                                                                    <TableCell align="center" key={key}><Button onClick={() => getDataPrayer(row)} disabled={(row.status == 0 || !row.id) && true} variant="contained">להצגה</Button></TableCell> :
                                                                    <TableCell align="center" key={key}>{value == "status" ? status[row[value]] : row[value]}</TableCell>
                                                            )
                                                        })
                                                    }
                                                    <TableCell align="center" key={key}><Button onClick={() => deleteName(row["_id"])} variant="contained">מחיקה</Button></TableCell>
                                                </TableRow>
                                            ))}
                                            {emptyRows > 0 && (
                                                <TableRow style={{ height: 53 * emptyRows }}>
                                                    <TableCell colSpan={6} />
                                                </TableRow>
                                            )}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                                    colSpan={3}
                                                    count={users.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                    ActionsComponent={TablePaginationActions}
                                                />
                                            </TableRow>
                                        </TableFooter>
                            </Table>
                            </TableContainer>
                            <Dialog
                                open={open && true}
                                onClose={() => setOpen(false)}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    פרטי השם עליו מתפללים
                                </DialogTitle>
                                <DialogContent>
                                    {
                                        rows.map((value, key) => {
                                            return (
                                                <div key={key}>
                                                    <strong>{head[value]}: </strong><span>{open[value]}</span>
                                                </div>
                                            )
                                        })
                                    }
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setOpen(false)}>סגרי</Button>
                                </DialogActions>
                            </Dialog>
                        <Actions setLoader={setLoader} setLoad={setLoad} load={load} type={"Progeny"} data={users} />
                    </>
            }
        </div>
    );
};

export default Prongry;

