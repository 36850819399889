import React, { useState } from "react";
import { Modal } from "@mui/material";
import { useFormik } from 'formik';
import './style.scss';
// import { progeny, shidduch } from "./utils";
import whatsapp from "./img/whatsapp.svg"
import SentSucsses from "./SentSucsses";
import dataService from "../../services/data.service";

export default function ContactForm({open, setOpen}) {

  const [error, setError] = useState(false);
  const [sent, setSent] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: ""
    },
    onSubmit: async values => {
      setError(false);
      // setOpen(false)
      // sendForm(values, setError, setSent)
      const res = await dataService.sendForm(values);
      setSent(true)
    },
  });

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="contact-form">
        <h2>
          <p>יש לך משהו לשאול אותנו?</p>
        </h2>
          {
            sent ? <SentSucsses /> :
            <form>
              <div>
                <label>שם</label>
                      <input
                  name="name"
                        type="text"
                        onChange={formik.handleChange}
                  value={formik.values.name}
                />
              </div>
              <div>
                <label>מייל</label>
                <input
                  name="email"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
              </div>
              <div>
                <label>הודעה</label>
                <textarea
                  name="message"
                  type="textarea"
                  onChange={formik.handleChange}
                  value={formik.values.message}
                />
              </div>
                <button type="submit" onClick={formik.handleSubmit}>שלח</button>
                {error && <p>{error}</p>}
              </form>
        }
        <a className="phone" target={"_blank"} href="https://wa.me/+972547022488">
          <span>ליצירת קשר ב: </span>
          <img src={whatsapp} />
          <span className="number">0547022488</span>
        </a>
      </div>
    </Modal>
  );
}