import React, { useEffect, useState } from "react";
import Login from "../Login";
import { Menu } from "../Menu";
import './style.scss';

export default function Admin() {

  const [verify, setVerify] = useState(false)

  useEffect(() => {
    if(localStorage.getItem('token')){
      setVerify(true)
    } else {
      setVerify(false)
    }
  }, [verify])

  return (
    verify ? <Menu /> : <Login setVerify={setVerify} />
  );
}