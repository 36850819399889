import React from "react";
import './style.scss';


export default function QAndA() {

  const data = [
    {
      title: "  שלום מה עלי לעשות ? ברצוני להעביר שם לזיווג הגון?",
      content: `אם ברצונך להעביר שם מסוים לתפילה - עליך להשלים את הטופס המתאים ובכך תקבלי שם אחר  - עליו את  תתפללי .`
    },
    {
      title: "ומה עלי לעשות לזכותו?",
      content: `לקבל שבת עשר דקות יותר מוקדם מהרגיל.
      לדוגמא, אם קיבלת שבת ב 18:00, אז תכניסי שבת ב 17:50`
    },
    {
      title: "ואם ארצה להעביר של זוג שזקוק לזרע של קיימא ?",
      content: `תקבלי שמות של זוג שממתין לאותה ישועה ועליהם תתפללי, שוב - שני אפשרויות - מתוכם עלייך לבחור באחד:
  
      אמירת תפילת חנה / אמירת פרק שירה 
      `
    },
    {
      title: "אם שכחתי שבוע אחד מה עלי לעשות?",
      content: `נשמח שתשלימי במשך השבוע.`
    },
    {
      title: "אם יש בחודש 5 שבועות מה עושים?",
      content: `השם יהיה תקף לכל חמשת השבועות ובסופם תצטרכי להירשם מחדש .`
    },
    {
      title: "האם אפשר להצטרף גם באמצע חודש?",
      content: `לא.
      עדיף להצטרף בתחילת חודש - שימי לב לתזכורת או במייל / בסטטוס,
     אך ניתן ליצור קשר בראש העמוד "בצור קשר"`
    }


  ]


  return (
    <div className="QAndA">
      <div className="content">
        <h2>
          <p>שאלות ותשובות</p>
        </h2>
        <div className="bubbles">
          {
            data.map((q, index) => {
              return (
                <div className={`bubble-${index}`}>
                  <img src={require(`./img/bubble${index}.svg`)} />
                  <div>
                    <p className="title">{q.title}</p>
                    <p className="text">{q.content}</p>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  );
}