import React, { useState } from 'react';
import { Box, Tabs, Tab } from "@mui/material";
import Progeny from './Progeny';
import Shidduch from './Shidduch';
import "./style.scss"

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Users = () => {

  const [value, setValue] = useState(0);

  return (
    <div className="Users">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={(e, n) => setValue(n)} variant="fullWidth" TabIndicatorProps={{ style: { background: "#FFF7F2" } }}>
          <Tab label="זרע של קיימא" {...a11yProps(0)} />
          <Tab label="זיווג הגון" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Progeny />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Shidduch />
      </TabPanel>
    </div>
  );
};

export default Users;
