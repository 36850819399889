import { sendData } from "./sendData";

class UserDataService {
  login(data) {
    return sendData("/login", data)
  }
  verify(data) {
    return sendData("/verify", data)
  }
}

export default new UserDataService();