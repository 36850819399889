import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from "@mui/material";

const ModalCheckMail = ({ open, setOpen, setEmail, email, action }) => {
    return <Dialog
        open={open && true}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            לאיזה מייל לשלוח?
        </DialogTitle>
        <DialogContent>
            {
                <input type="email"  placeholder="הכנסי מייל" value={email} onChange={(e) => setEmail(e.target.value)} />

                // <TextField
                //     label="מייל"
                //     variant="standard"
                //     value={email}
                //     onChange={(e) => setEmail(e.target.value)}
                // />
            }
        </DialogContent>
        <DialogActions>
            <Button onClick={() => action()}>שלחי מייל</Button>
        </DialogActions>
    </Dialog>
}

export default ModalCheckMail;

