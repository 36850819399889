import React from 'react';
import Users from '../Users';
import Progeny from '../Users/Progeny';
import Shidduch from '../Users/Shidduch';
import Salvation from '../Salvation';
import "./style.scss"
import { Link, Route, Routes } from 'react-router-dom';
import AddSoldiers from '../AddSoldiers';

export const Menu = () => {
    return (
        <div className="admin">
            <nav>
                <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="users/progeny">זרע של קיימא</Link>
                    </li>
                    <li>
                        <Link to="users/shidduch">זיווג הגון</Link>
                    </li>
                    <li>
                        <Link to="salvation">סיפורי ישועות</Link>
                    </li>
                    <li>
                        <Link to="addSoldiers">הכנסי שם של חייל</Link>
                    </li>
                </ul>
            </nav>
            <Routes>
                <Route exact path="/users/progeny" element={<Progeny />} />
                <Route exact path="/users/shidduch" element={<Shidduch />} />
                <Route exact path="/salvation" element={<Salvation />} />
                <Route exact path="/addSoldiers" element={<AddSoldiers />} />
            </Routes>
        </div>
    );
}