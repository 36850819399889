import React, { useState } from "react";
import { Box, Tabs, Tab, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import './style.scss';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Instructions() {

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const data = [
    {
      title: "מוסרים שם של זוג בטופס בסוף העמוד",
      content: `
      ראשית עליך להזין את הפרטים שלך בטופס הזה:
      שמך ושם בעלך - כולל שם האמא - שימי לב בלא שם האמא המערכת לא תוכל לשלוח לך שם.
      אח"כ הזיני את כתובת המייל שלך, והמערכת תשלח לך בהקדם את שמות הזוג עליהם תתפללי 
      `
    },
    {
      title: "בוחרים בין תפילת חנה/פרק שירה",
      content: `עלייך לבחור באחת התפילות להלן, שאותו תאמרי בליל שבת בהדלקת נרות

      1 ) תפילת חנה - 
      תפילה זו נכתבה ע"י חנה אשת אלקנה ואמו של שמואל הנביא. אשה גדולה שניתן ללמוד ממנה רבות הן בתפילתה והן בחינוך המדהים שהנחילה לנו בהתנהגותה ועמידתה בנסיון.
      את שמואל זכתה לקבל במתנה גמורה לאחר הרבה שנות ציפייה - כשעמדה ומתוך כאב התפללה, זכתה שתפילה זו תעבור לכל הדורות הבאים ותשמש כסגולה לזרע של קיימא. 
            
      התפילה מובאת מספר שמואל א , פרק ב , פסוקים א - י, כמו כן היא נמצאת גם בספר תחינות. 
      
      2 ) פרק שירה - 
      פרק שירה הוא סגולה מדהימה לישועות רבות, 
      אמנם לא נמצא קשר מסוים לזרש"ק, אך זו תפילה יפה כל כך על שירת החי והצומח  ....
      אז אני בטוחה שתעמוד לזוג לזכות.
      `
    },
    {
      title: "בשליחת הטופס מקבלים שם של זוג אחר",
      content: `חשוב להדגיש:
      אם תרצי להעביר את שמך או שם מכרייך לחודש נוסף , עליך להירשם שוב!
      `
    },
    {
      title: "כל שבת למשך חודש מתפללים על שמות הזוג",
      content: `ההתחיבות היא למשך חודש, ובכל 4 שבועות תתקיים הרשמה נוספת  
      `
    },
    {
      title: "מתחילים סבב חדש בסוף חודש",
      content: `חשוב להדגיש:
      אם תרצי להעביר את שמך או שם מכרייך לחודש נוסף , עליך להירשם שוב!
      `
    }
  ]

  const dataOfZivoog = [
    {
      title: "מוסרים שם בטופס בסוף העמוד",
      content: `
      ראשית עליך להזין את הפרטים שלך בטופס הזה:
      שמך כולל שם האמא - שימי לב בלא שם האמא המערכת לא תוכל לשלוח לך שם.
      אח"כ תזיני את כתובת המייל שלך, והמערכת תשלח לך בהקדם שם עליו תתפללי 
      `
    },
    {
      title: "מתחייבים לקבל שבת עשר דקות מוקדם מהרגיל",
      content: `
        בזמן זה תוכלי להתפלל על השם אותו קיבלת
      `
    },
    {
      title: "בשליחת הטופס תקבלי שם לתפילה",
      content: `חשוב להדגיש:
      אם תרצי להעביר את שמך או שם מכרייך לחודש נוסף , עליך להירשם שוב!
      `
    },
    {
      title: "ולמשך חודש תתפללי על השם אותו קיבלת",
      content: `ההתחיבות היא למשך חודש, ובכל 4 שבועות תתקיים הרשמה נוספת  
      `
    },
    {
      title: "מתחילים סבב חדש בסוף חודש",
      content: `חשוב להדגיש:
      אם תרצי להעביר את שמך או שם מכרייך לחודש נוסף , עליך להירשם שוב!
      `
    }
  ]

  const [expanded, setExpanded] = useState('panel0');

  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div id="instructions" className="instructions">
      <div className="content">
        <h2>
          <p>כל כך מרגש שאת מצטרפת וזוכה להיות חלק מקבוצתינו</p>
          <p>מה עליך לעשות?</p>
        </h2>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} variant="fullWidth" TabIndicatorProps={{ style: { background: "#FFF7F2" } }}>
            <Tab label="זרע של קיימא" {...a11yProps(0)} />
            <Tab label="זיווג הגון" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {
            data.map((s, index) => {
              return (
                <Accordion className={`accordion ${expanded === `panel${index}` ? "open" : "close"}`} key={index} expanded={expanded === `panel${index}`} onChange={handleChangeAccordion(`panel${index}`)}>
                  <AccordionSummary aria-controls={`panel${index}d-content`} id={`panel${index}d-header`}>
                    <div className="summary">
                      <p className="title"><span className="number">{index + 1}</span> {s.title}</p>
                      <p className={`more ${expanded === `panel${index}` ? "open" : "close"}`}>{ expanded === `panel${index}` ? <span>סגור פירוט</span> : <span>לפירוט נוסף</span> }</p>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="text"> {s.content} </div>
                  </AccordionDetails>
                </Accordion>
              )
            })
          }
        </TabPanel>
        <TabPanel value={value} index={1}>
        {
            dataOfZivoog.map((s, index) => {
              return (
                <Accordion className={`accordion ${expanded === `panel${index}` ? "open" : "close"}`} key={index} expanded={expanded === `panel${index}`} onChange={handleChangeAccordion(`panel${index}`)}>
                  <AccordionSummary aria-controls={`panel${index}d-content`} id={`panel${index}d-header`}>
                    <div className="summary">
                      <p className="title"><span className="number">{index + 1}</span> {s.title}</p>
                      <p className={`more ${expanded === `panel${index}` ? "open" : "close"}`}>{ expanded === `panel${index}` ? <span>סגור פירוט</span> : <span>לפירוט נוסף</span> }</p>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="text"> {s.content} </div>
                  </AccordionDetails>
                </Accordion>
              )
            })
          }
        </TabPanel>
      </div>
    </div>
  );
}