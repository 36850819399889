import usersService from "../../services/users.service";

const authService = {
  isAuthenticated: false,
  async login() {
    let res = await usersService.verify()
    console.log(res, "res")
    this.isAuthenticated = localStorage.getItem('token') && true;
  }
};

export default authService;