import React, { useEffect, useState } from 'react';
import "./style.scss"
import JoditEditor from 'jodit-react';

import { useFormik } from 'formik';
import adminService from '../../../services/admin.service';
import { Button, Checkbox } from '@mui/material';
import axios from 'axios';

const Salvation = () => {

    const [salvations, setSalvations] = useState(false);
    const [error, setError] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [file, setFile] = useState(null);

    useEffect(() => {
        const getSalvation = async () => {
            const res = await adminService.getSalvation();
            if (res?.data)
                setSalvations(res.data)
        }
        getSalvation()
    }, [])

    const formik = useFormik({
        initialValues: {
            title: "",
            text: "",
            homepage: false
        },
        onSubmit: async values => {
            if (!values.text || !values.title) {
                setError("כל השדות חובה.");
                setDisabled(false);
            } else {
                values.filename = await handleUpload();
                console.log(values, "values.filename")
                setError(false); setDisabled(true);
                await adminService.insertSalvation(values);
                setDisabled(false);
            }
        },
    });

    const handleCheck = (index) => {
        let newArr = [...salvations];
        newArr[index]["homepage"] = !salvations[index].homepage;
        setSalvations(newArr);
    }

    const updateCheckStories = async () => {

        await adminService.updateCheckStories(salvations);
    }

    const handleUpload = async () => {
        const formData = new FormData();
        formData.append('image', file);

        const res = await axios.post('/upload', formData);
        return res?.data?.file?.filename
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleRemove = async (i) => {
        await adminService.deleteSalvation({ id: salvations[i]._id });
    }

    return (
        <div className="salvation">
            <div className="content">
                <h2> הכניסי סיפור ישועה </h2>
                {
                    <form>
                        <p className="title">כותרת</p>
                        <input
                            name="title"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.title}
                        />
                        <p className="title">תוכן</p>
                        <JoditEditor
                            name="text"
                            value={formik.values.text}
                            onChange={newContent => formik.setFieldValue("text", newContent)}
                        />
                        <span className="title">הצגה בעמוד הבית</span>
                        <Checkbox
                            name="homepage"
                            checked={formik.values.homepage}
                            onClick={formik.handleChange}
                        />
                        <input type="file" onChange={handleFileChange} />
                        <Button variant="contained" className="send" type="submit" disabled={disabled} onClick={formik.handleSubmit}>שלח</Button>
                        {error && <p>{error}</p>}
                    </form>
                }
                {
                    salvations.length &&
                    <>
                        { salvations.map((s, i) => {
                            return <div key={i} className="salvationStory">
                                <Checkbox
                                    defaultChecked={s.homepage || false}
                                    onClick={() => { handleCheck(i) }}
                                    checked={s.homepage || false}
                                />
                                <div className="delete" onClick={() => handleRemove(i)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 30 30">
                                        <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
                                    </svg>
                                </div>
                                <div>
                                    <p><strong>{s.title}</strong></p>
                                    <div className="content" dangerouslySetInnerHTML={{ __html: s.text }}></div>
                                </div>
                            </div>
                        }) }
                        <Button variant="contained" className="send" type="submit" onClick={() => updateCheckStories()}>עדכן</Button>
                    </>
                }
            </div>
        </div>
    );
}

export default Salvation;