
const arrangeDataBeforesending = async (data) => {
    await data.map(d => {
        let f = data.find(f => d["_id"] == f.id);
        if (f) d["emailPrayer"] = f.email;
        delete d.status
        delete d.updater
        delete d.createAt
        delete d["__v"]
        return d
    })
    return data;
}

export {
    arrangeDataBeforesending
}