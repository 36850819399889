import React from "react";
import './style.scss';
import logo from './img/logo.svg'

function Menu({ setContactForm }) {
  return (
    <div className="menu">
      <div className="logo">
        <a href="/" ><img src={logo} /></a>
      </div>
      {
        setContactForm &&
          <nav>
            <a className="link" href="#about" variant="contained" >אודות</a>
            {/* <a className="link" href="#stories-salvations" variant="contained" >סיפורי ישועות</a> */}
            <a onClick={() => setContactForm(true)} className="link" variant="contained" >צור קשר</a>
          </nav>
      }
      <div className="btnDonation">
        <a target="_blank" href="https://www.matara.pro/nedarimplus/online/?mosad=7002101">לתרומה</a>
      </div>
    </div>
  );
}

export default Menu;
