import { sendData } from "./sendData";

class israelLivesService {
  insertAndGetName(data) {
    return sendData("/israel-lives/insertAndGetName", data);
  }
  onlyInsertName(data) {
    return sendData("/israel-lives/onlyInsertName", data);
  }
}

export default new israelLivesService();