import React, { useState } from 'react';
import JoditEditor from 'jodit-react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from "@mui/material";
import adminService from '../../../services/admin.service';

const ModalPropertyEmail = ({ type, setOpen }) => {

    const [title, setTitle] = useState("")
    const [text, setText] = useState("")

    const action = async () => {
        await adminService.updateDataEmail({ type, title, text });
        setOpen(false)
    }

    return <Dialog
        open={true}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            לאיזה מייל לשלוח?
        </DialogTitle>
        <DialogContent>
            <input type="text" placeholder="כותרת למייל" value={title} onChange={(e) => setTitle(e.target.value)} />
            <JoditEditor
                name="text"
                value={text}
                onChange={newContent => setText(newContent)}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={() => action()}>עדכון</Button>
        </DialogActions>
    </Dialog>
}

export default ModalPropertyEmail;

