import { sendData } from "./sendData";

class UserDataService {
  insertProgeny(data) {
    return sendData("/insertProgeny", data)
  }
  insertShidduch(data) {
    return sendData("/insertShidduch", data)
  }
  getDataByType(data) {
    return sendData("/getDataByType", data)
  }
  sendForm(data) {
    return sendData("/sendForm", data)
  }
  createLotteryProgeny() {
    return sendData("/createLotteryProgeny")
  }
  removeLotteryProgeny() {
    return sendData("/removeLotteryProgeny")
  }
  sendEmailForLotteryProgeny() {
    return sendData("/sendEmailForLotteryProgeny")
  }
  removeDuplicateProgeny() {
    return sendData("/removeDuplicateProgeny")
  }
  removeStatusDone(type) {
    return sendData("/removeStatusDone", type)
  }
  createLotteryShidduch() {
    return sendData("/createLotteryShidduch")
  }
  removeLotteryShidduch() {
    return sendData("/removeLotteryShidduch")
  }
  sendEmailForLotteryShidduch() {
    return sendData("/sendEmailForLotteryShidduch")
  }
  removeDuplicateShidduch() {
    return sendData("/removeDuplicateShidduch")
  }
  deleteById(data) {
    return sendData("/deleteById", data)
  }
  sendCheckEmail(type) {
    return sendData("/sendCheckEmail", type)
  }
}

export default new UserDataService();