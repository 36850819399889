import React from "react";
import './style.scss';
import ellipaeThere from "./img/ellipse-there.svg"

function About() {
  return (
    <div className="about" id="about">
      <div>
        <img className="ellipse-there" src={ellipaeThere} />
        <div className="content">
          <div className="title">
            <p>אודות המיזם.</p>
            <p>איך הכל התחיל...</p>
          </div>
          <div className="text">
            <div>
              <p>מיזם זה נוצר בעקבות ציפייה מתוקה ולא פשוטה לזרע של קיימא, כחלק מהתהליך שאותו חוויתי ומתוך הבנה שהציפייה כרוכה עם תפילה - הרגשתי נחמה מסוימת בכך שהתפללתי על נשים נוספות שהכרתי. הקושי של הבדידות והריקנות הוקל כשאני יודעת כי ישנן דמויות נוספות ואולי וירטואליות, שעליהן ואיתן יחד משתוקקת לזכות לחבוק ילד!</p>
              <p>
                <span className="block">הקשר הקרוב שנהיה לי בכך שהזכרתי אותם בכל תפילה מתפילותי - הוא מדהים,</span>
                 פתאום הן היו חשובות לי יותר מהכל, ורציתי שהן לא תתייאשנה ותמיד תזכורנה שהן זוכות להלחם בקרב אמונה חזק - בין יאוש לתקוה.</p>
              <p>כשהחלטתי לפרסם את הסגולה שגיליתי של "כל המבקש רחמים על חברו" אצל כמה ממכרותי הן לא האמינו שזה יחזיק מעמד, אך לאט לאט זה צבר תאוצה והבנתי שעמ"י זקוק אחד לשני .</p>
            </div>
            <div>
              <p>כל אחד מכיר את קשייו בצורה מצוינת ומודע לחולשותיו שלו בעל פה , אך המקום שבו אתה מקדיש מעצמך גם למען השני - הוא כל כך חזק שלא סתם אמרו חכמינו “כל המתפלל על חבירו והוא זקוק לאותו דבר נענה תחילה”.</p>
              <p className="paddingTop">
                <span className="block">אפשר להשוות את זה בימינו בענין הציפייה לגאולה -כל מעשה שאדם עושה לחוד הוא במשמעות עצומה אך כשכולם מתאחדים ויוצרים טוב יחד - זה מחיש את הגאולה!</span>
                <span className="block">כך כשאני מתפללת יש לתפילה יופי מיוחד:</span>
                <span className="block">היא ואני מתאחדות באותו זמן לתפילה אחת על השניה...</span>
              </p>
              <p>אני בטוחה שזה יוצר רעש גדול בשמים - ובטח עושה נחת רוח להקב"ה מאיתנו. </p>
              <strong>וכך נולד לו המיזם ותודה שאת חלק ממנו <span className="red">❤</span></strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
