import React, { useEffect, useState } from "react";
import salvationService from "../../services/salvation.service";
import background from "./img/background.png"
import './style.scss';

function Stories() {

  const [salvation, setSalvation] = useState()

  useEffect(() => {

    const getSalvation = async () => {
      const res = await salvationService.getSalvationAll()
      if (res?.data) setSalvation(res.data);
    }

    getSalvation()
  }, [])

  return (
    <> {
      salvation?.length &&
      <div id="stories" className="stories">
        <img className="background" src={background} />
        <div className="content-float" >
          <div className="title">
            <h2>התינוקות שלנו</h2>
          </div>
          {salvation?.map((item, index) => (
            <div
              key={index}
              className="story"
            >
              <img src={`/uploads/${item.filename}`} width="100" height="100" alt={`Item ${index + 1}`} />
              <div>
                <h2>{item.title}</h2>
                <div dangerouslySetInnerHTML={{ __html: item.text }}></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    } </>
  );
}

export default Stories;
