import React, { useEffect, useRef, useState } from "react";
import { Box, Tabs, Tab, Select, MenuItem, FormControlLabel, Checkbox } from "@mui/material";
import { ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';
import './style.scss';
import { progeny, shidduch } from "./utils";
import SentSucsses from "./SentSucsses";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Joining({ setOpen }) {

  const [value, setValue] = useState(0);
  const [error, setError] = useState(false);
  const [sent, setSent] = useState(false);
  const [checkGender, setCheckGender] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [hasOpened, setHasOpened] = useState(false);
  const sectionRef = useRef(null);
  const timerRef = useRef(null);

  const tfilot = ["תפילת חנה", "פרק שירה"];
  const tfilotZ = ["שבת עשר דקות יותר מוקדם", 'אמירת שירת הים (”אז ישיר”)'];

  const validationSchema = Yup.object().shape({
    email: Yup.string().required().email('כתובת המייל לא תקינה'),
    nameOfWoman: value == 0 ? Yup.string().required() : Yup.string(),
    motherOfWoman: value == 0 ? Yup.string().required() : Yup.string(),
    nameOfHusband: Yup.string().required(),
    motherOfHusband: Yup.string().required(),
    tfila: Yup.string().required(),
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !hasOpened) {
          timerRef.current = setTimeout(() => {
            setOpen(true);
            setHasOpened(true);
          }, 30000);
          observer.disconnect();
          return () => {
            clearTimeout(timerRef.current);
            observer.disconnect();
          };
        } else {
          clearTimeout(timerRef.current);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1, 
      }
    );
    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, []);

  const formik = useFormik({
    initialValues: {
    nameOfWoman: "",
    motherOfWoman: "",
    nameOfHusband: "",
    motherOfHusband: "",
    email: "",
    tfila: "",
    checkBoxMail: false
    },
    validationSchema,
    onSubmit: async values => {
      setSent(true);
      setHasOpened(true);
      setError(false); setDisabled(true);
      if (value === 0) progeny(values, setError, setSent)
      else shidduch(values, checkGender, setError, setSent)
    },
  });

  const handleChange = (event, newValue) => {
    setSent(false);
    setDisabled(false);
    setError(false);
    formik.resetForm();
    setValue(newValue);
  };

  const getError = (field) => {
    return formik.errors[field] ? "error" : "";
  }

  const sentAgain = () => {
    formik.resetForm();
    setDisabled(false);
    setSent(false); 
  }

  return (
    <div id="joining" className="joining" ref={sectionRef}>
      <div className="content">
        <h2>
          <p>על מה את מתפללת החודש?</p>
          <p><strong>מלאי את הטופס הרלוונטי, אפשר גם את שניהם :)</strong></p>
        </h2>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} variant="fullWidth" TabIndicatorProps={{ style: { background: "#FFF7F2" } }}>
            <Tab label="זרע של קיימא" {...a11yProps(0)} />
            <Tab label="זיווג הגון" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {
            sent ? <SentSucsses setSent={(sentAgain)} /> :
              <form>
                <div className="names">
                  <div>
                    <label>שם האישה (כולל שם האמא)</label>
                    <div>
                      <input
                        name="nameOfWoman"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.nameOfWoman}
                        className={getError("nameOfWoman")}
                      />
                      <span>בת</span>
                      <input
                        name="motherOfWoman"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.motherOfWoman}
                        className={getError("motherOfWoman")}
                      />
                    </div></div>
                  <div>
                    <label>שם הבעל (כולל שם האמא)</label>
                    <div>
                      <input
                        name="nameOfHusband"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.nameOfHusband}
                        className={getError("nameOfHusband")}
                      />
                      <span>בן</span>
                      <input
                        name="motherOfHusband"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.motherOfHusband}
                        className={getError("motherOfHusband")}
                      />
                    </div>
                  </div>
                </div>
                <div className="details">
                  <div>
                    <label>כתובת מייל לתזכורת</label>
                    <input
                      name="email"
                      type="email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      className={getError("email")}
                    />
                    {/* <ErrorMessage name="email" component="div" className="error" /> */}
                  </div>
                  <div>
                    <label>בחרי את אחד התפילות</label>
                    <Select
                      name="tfila"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formik.values.tfila}
                      onChange={formik.handleChange}
                      className={getError("tfila")}
                    >
                      {
                        tfilot.map((t, index) => {
                          return <MenuItem key={index} value={t}>{t}</MenuItem>
                        })
                      }
                    </Select>
                  </div>
                </div>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="checkBoxMail"
                      checked={formik.values.checkBoxMail}
                      onChange={formik.handleChange}
                      style={{
                        color: "#FFDED6"
                      }}
                    />
                  }
                  label="מעונינת בקבלת תזכורות למייל מידי שבוע" />
                <button type="submit" disabled={disabled} onClick={formik.handleSubmit}>שלח</button>
                {error && <p>{error}</p>}
              </form>
          }
        </TabPanel>
        <TabPanel value={value} index={1}>
          {
            sent ? <SentSucsses setSent={(sentAgain)} /> :
              <form>
                <div className="names shidduch">
                  <div>
                    <label>שם לתפילה</label>
                    <div>
                      <input
                        name="nameOfHusband"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.nameOfHusband}
                        className={getError("nameOfHusband")}
                      />
                      <span className="select">
                        <span className="radio son" onClick={() => setCheckGender(true)}>
                          <span className={checkGender ? "check" : ""} ></span>
                        </span>
                        <span>בן</span>
                        <span className="radio daughter" onClick={() => setCheckGender(false)}>
                          <span className={!checkGender ? "check" : ""} ></span>
                        </span>
                        <span>בת</span>
                      </span>
                      <input
                        name="motherOfHusband"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.motherOfHusband}
                        className={getError("motherOfHusband")}
                      />
                    </div>
                  </div>
                </div>
                <div className="details">
                  <div>
                    <label>כתובת מייל לתזכורת</label>
                    <input
                      name="email"
                      type="email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      className={getError("email")}
                    />
                  </div>
                  <div>
                    <label>בחרי את התחייבותך</label>
                    <Select
                      name="tfila"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formik.values.tfila}
                      onChange={formik.handleChange}
                      className={getError("tfila")}
                    >
                      {
                        tfilotZ.map((t, index) => {
                          return <MenuItem key={index} value={t}>{t}</MenuItem>
                        })
                      }
                    </Select>
                  </div>
                </div>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="checkBoxMail"
                      checked={formik.values.checkBoxMail}
                      onChange={formik.handleChange}
                      style={{
                        color: "#FFDED6"
                      }}
                    />
                  }
                  label="מעונינת בקבלת תזכורות למייל מידי שבוע" />
                <button type="submit" disabled={disabled} onClick={formik.handleSubmit}>שלח</button>
                {error && <p>{error}</p>}
              </form>
          }
        </TabPanel>
      </div>
    </div>
  );
}